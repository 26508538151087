import { Controller } from "stimulus"
import { useDebounce } from 'stimulus-use';

export default class extends Controller {
  static debounces = ["submit"]
  static targets = ["form", "input"]

  connect() {
    useDebounce(this)
    this.element.addEventListener("turbo:submit-end", this.submitEnd.bind(this))
  }

  disconnect() {
    this.element.removeEventListener("turbo:submit-end", this.submitEnd.bind(this))
  }

  submit(event) {
    event.preventDefault();
    clearTimeout(this.submitTimeout);
    this.submitTimeout = setTimeout(() => {
      this.formTarget.requestSubmit();
    }, 300);
  }

  submitEnd() {
    this.inputTarget.focus()
  }
}
