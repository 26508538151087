import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static classes = ["isActive"]
    static targets = ["burger", "navbar"]

    toggle() {
        this.navbarTarget.classList.toggle(this.isActiveClass)
        this.burgerTarget.classList.toggle(this.isActiveClass)
    }
}