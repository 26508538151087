import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bulk-edit"
export default class extends Controller {
  static targets = ["checkbox", "actions"]

  connect() {
    this.updateBulkActionsVisibility()
  }

  toggleBulkActions(event) {
    this.updateBulkActionsVisibility()
  }

  updateBulkActionsVisibility() {
    const checkedBoxes = this.checkboxTargets.filter(cb => cb.checked)
    if (checkedBoxes.length > 0) {
      this.actionsTarget.classList.remove('is-hidden')
    } else {
      this.actionsTarget.classList.add('is-hidden')
    }
  }
}
