import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["isActive"]
  static targets = ["modal"]

  toggle(event) {
    event.preventDefault()
    this.modalTarget.classList.toggle(this.isActiveClass)
  }
}
