import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import "./controllers"

import "trix"
import "@rails/actiontext"

// Uncomment the line below to turn off turbo globally
// Turbo.session.drive = false

Rails.start()
