import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="teammate-autofill"
export default class extends Controller {
  static targets = ["name", "email", "teammate"];

  fill(event) {
    const email = this.teammateTarget.value
    const name = this.teammateTarget.selectedOptions[0].textContent
    if (name != email) {
      this.nameTarget.value = name
    }
    this.emailTarget.value = email
  }
}
