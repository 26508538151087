import { Controller } from '@hotwired/stimulus';
import { useTransition } from 'stimulus-use';

export default class extends Controller {
  static targets = ['animate']

  connect() {
    useTransition(this, {
      element: this.animateTarget,
      enterActive: 'fade-enter-active',
      enterFrom: 'fade-enter-from',
      enterTo: 'fade-enter-to',
      leaveActive: 'fade-leave-active',
      leaveFrom: 'fade-leave-from',
      leaveTo: 'fade-leave-to',
      hiddenClass: 'is-hidden',
      transitioned: true,
    });
  }

  close() {
    this.leave();
  }

  open() {
    this.enter();
  }

  toggle() {
    this.toggleTransition();
  }
}
