import { Controller } from '@hotwired/stimulus';
import { useTransition } from 'stimulus-use';

export default class extends Controller {
  static targets = ['flash'];

  flashTargetConnected(target) {
    useTransition(this, {
      element: target,
      enterActive: 'fade-enter-active',
      enterFrom: 'fade-enter-from',
      enterTo: 'fade-enter-to',
      leaveActive: 'fade-leave-active',
      leaveFrom: 'fade-leave-from',
      leaveTo: 'fade-leave-to',
      hiddenClass: '',
      // set this, because the item *starts* in an open state
      transitioned: true,
    });
  }

  close() {
    this.leave();
  }

  open() {
    this.enter();
  }

  toggle() {
    this.toggleTransition();
  }

  hide(event) {
    event.currentTarget.closest('.notification').remove();
  }
}
