import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="contact-autofill"
export default class extends Controller {
  static classes = ["hidden"]
  static targets = ["label", "city", "state", "email", "phone", "contact", "static", "form"];
  static values = { hunter: String }

  fill(event) {
    const contactId = this.contactTarget.value
    if (contactId == "") {
      this.showForm()
      this.labelTarget.value = ""
    } else {
      this.hideForm()
      fetch(`/hunters/${this.hunterValue}/contacts/${contactId}.turbo_stream`, {
        headers: {
          'Accept': 'text/vnd.turbo-stream.html'
        }
      })
        .then(response => response.text())
        .then(text => Turbo.renderStreamMessage(text));
    }
  }

  hideForm() {
    this.formTarget.classList.add(this.hiddenClass);
    this.cityTarget.disabled = true
    this.stateTarget.disabled = true
    this.emailTarget.disabled = true
    this.phoneTarget.disabled = true
    this.staticTarget.classList.remove(this.hiddenClass);
  }

  showForm() {
    this.formTarget.classList.remove(this.hiddenClass);
    this.cityTarget.disabled = false
    this.stateTarget.disabled = false
    this.emailTarget.disabled = false
    this.phoneTarget.disabled = false
    this.staticTarget.classList.add(this.hiddenClass);
  }
}
