import Sortable from '@stimulus-components/sortable'

export default class extends Sortable {
  onUpdate(event) {
    super.onUpdate(event)
    // update the hidden position field
    this.updatePosition(event)
  }

  updatePosition(event) {
    const { item } = event
    item.querySelectorAll('[data-position]').forEach((element, index) => {
      const input = element.querySelector('input[type="hidden"]')
      if (input) {
        input.value = index + 1
      }
    })
  }
}
