import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["isActive"]
  static targets = ["dropdown"]

  toggle() {
    this.dropdownTarget.classList.toggle(this.isActiveClass)
  }
}
