import { Application } from "@hotwired/stimulus"
import { Confetti } from "stimulus-confetti"
import Sortable from "@stimulus-components/sortable"
import TextareaAutogrow from "stimulus-textarea-autogrow"

const application = Application.start()

// 3rd party stimulus libraries
application.register("confetti", Confetti)
application.register("sortable", Sortable)
application.register("textarea-autogrow", TextareaAutogrow)

// Configure Stimulus development experience
application.warnings = true
application.debug = false
window.Stimulus = application

export { application }
