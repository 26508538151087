import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["clicker"]
  static values = {
    delay: {
      type: Number,
      default: 1000
    }
  };

  connect() {
    setTimeout(() => {
      this.clickerTargets.forEach((element) => {
        // lol
        console.log("Clicking on element", element);
        element.click();
      })
    }, this.delayValue);
  }
}
