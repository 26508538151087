import { Controller } from "@hotwired/stimulus"

// From https://domchristie.co.uk/posts/hotwire-back-button-2024-05-12/
export default class extends Controller {
  back(event) {
    if (this.#shouldRestore) {
      event.preventDefault()
      window.history.back()
    }
  }

  get #shouldRestore() {
    return !this.#isFirstHistoryEntry
  }

  get #isFirstHistoryEntry() {
    return !window.history.state.turbo ||
      window.history.state.turbo.restorationIndex === 0
  }
}
